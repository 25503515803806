<template>
	<div class="h-100">
		<TheContainer />
	</div>
</template>

<script>
export default {
	name: "EditorModule",
	components: {
		TheContainer: () => import("../../shared/containers/TheContainer"),
	},
}
</script>

<style lang="scss" scoped></style>
